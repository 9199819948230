<template>
  <div :id="pageId">
    <!-- Render the main headline and image -->
    <HomePageHero v-if="topSection.data" v-bind="topSection.data" />
    <div class="container z-10 mx-auto pb-36 lg:px-3.5">
      <GridLayout :variant="gridVariant ?? 'fullBleed'" class="lg:gap-y-12">
        <div class="col-span-full flex flex-col gap-20">
          <template
            v-for="(section, index) in sectionsToShow"
            :key="section.componentName + pageId + index"
          >
            <DynamicComp
              v-if="index === 0"
              v-bind="section"
              :id="section.componentName + pageId + index"
              :key="section.componentName + pageId + index"
            />
          </template>
        </div>
        <div class="col-span-full flex flex-col gap-20 sm:gap-40">
          <!-- Render the sections -->
          <template
            v-for="(section, index) in sectionsToShow"
            :key="section.componentName + pageId + index"
          >
            <DynamicComp
              v-if="index !== 0"
              v-bind="section"
              :id="section.componentName + index + pageId"
              :key="section.componentName + index + pageId"
              :class="section.componentName?.toLocaleLowerCase()"
            />
          </template>
          <div class="flex flex-col gap-10">
            <HeadlineGroup v-bind="headlineGroupFifth" />
            <div class="flex flex-col gap-9 lg:gap-10 xl:flex-row">
              <div class="flex flex-col gap-6 xl:w-1/2">
                <Headline
                  class="font-semibold text-white"
                  text="GenTwo"
                  level="h5"
                  variant="small"
                />
                <WraplinkSlider
                  v-if="
                    JobsEventsToggle?.data?.showOpenJobs &&
                    jobsContainer.wrapLinks.length
                  "
                  title="Open Position"
                  v-bind="jobsContainer"
                  :slider-options="{ perPage: 1, speed: 1200 }"
                />
                <WraplinkList
                  v-if="getWrapLinkLists[0].data"
                  button-custom-style="padding: 0"
                  v-bind="getWrapLinkLists[0].data"
                />
              </div>
              <div class="flex flex-col gap-6 xl:w-1/2">
                <Headline
                  class="font-semibold text-white"
                  text="Register"
                  level="h5"
                  variant="small"
                />
                <WraplinkSlider
                  v-if="
                    JobsEventsToggle?.data?.showUpcomingEvents &&
                    eventsContainer.wrapLinks.length
                  "
                  title="upcoming events"
                  v-bind="eventsContainer"
                  :slider-options="{ perPage: 1, speed: 1200 }"
                />
                <div
                  v-else
                  class="bg-black-600 flex flex-col gap-3.5 p-3.5 lg:gap-3.5 lg:p-6"
                >
                  <span
                    class="font-mierb text-body text-black-200 font-semibold uppercase tracking-[0.7px]"
                    >No events for now!</span
                  >
                  <MaterialIcon name="NoContent" class="h-24 w-32" />
                </div>
                <WraplinkList
                  v-if="getWrapLinkLists[1].data"
                  button-custom-style="padding: 0"
                  v-bind="getWrapLinkLists[1].data"
                />
              </div>
            </div>
          </div>
        </div>
      </GridLayout>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute as vUseRoute } from "vue-router";
import { computed as vComputed, ref } from "vue";
import {
  tagBackground,
  tagColors,
  tagVariants,
  wraperListInterface,
} from "~/components/Wraplink/types";
import { useCmsifiedPageRendered } from "~/composables/useCmsifiedPageRendered";
import { MetaInfo, Section } from "~/types";
import MaterialIcon from "~/components/Icon/MaterialIcon.vue";

interface containerType {
  attributes: {
    headlineGroup?: {
      title?: string;
      subtitle?: string;
    };
    slug: string;
    title: string;
    sections: {
      date: string;
      location: string;
    }[];
    tags?: {
      data: {
        attributes: {
          backgroundColor?: tagBackground;
          href?: string;
          text?: string;
          titleColor?: tagColors;
          variant?: tagVariants;
        };
      }[];
    };
  };
}

const route = vUseRoute();
const { fetch } = useCmsifiedPageRendered();
const { find } = useStrapi4();
const slug = "home";

const {
  data: page,
}: {
  data: {
    gridVariant: String;
    sections: Section[];
    topSection: Section;
    pageMetaConfig: MetaInfo;
  };
} = await useAsyncData(`page-${route.params.slug}`, () => fetch(slug));

const {
  gridVariant,
  sections,
  topSection,
}: { gridVariant: String; sections: Section[]; topSection: Section } =
  page?.value ?? {};

const sectionsToShow = vComputed(() =>
  sections.filter(
    (section) =>
      section.componentName !== "Wrap_link_list" &&
      section.componentName !== "JobsEventsToggle"
  )
);

const JobsEventsToggle = vComputed(() =>
  sections.find((section) => section.componentName === "JobsEventsToggle")
);
const getWrapLinkLists = vComputed(() =>
  sections.filter((section) => section.componentName === "Wrap_link_list")
);
useHead(page?.value?.pageMetaConfig ?? {});
const pageId = vComputed(() => "home");

const headlineGroupFifth = {
  title: "Would you like to know more?",
  subtitle: "limitless, bold, authentic",
  variant: "compact",
};

const { data: jobs } = await find<unknown>("Jobs", {
  populate: "*",
});

const events: { data: [] } = await find("events");
const { data: webinars } = await useAsyncData("webinar-homepage", () =>
  find("resources", {
    sort: "publishedDate:desc",
    filters: {
      type: "Webinar",
      publishedDate: {
        $gte: new Date(),
      },
    },
  })
);

const jobsContainer = ref<{ wrapLinks: wraperListInterface[] }>({
  wrapLinks: [],
});
const eventsContainer = ref<{ wrapLinks: wraperListInterface[] }>({
  wrapLinks: [],
});

webinars?.value?.data.forEach(({ attributes }) => {
  eventsContainer.value.wrapLinks.push({
    headlineHighlighted: attributes?.title,
    subTitle: `${getFormattedDate(attributes?.template)?.date} - ${
      getFormattedDate(attributes?.template)?.time
    } - ${attributes?.template[0]?.["headline-main"]?.location}`,
    subTitleClassName:
      "text-black-200 text-bodyXLarge text-white font-medium font-mierb leading-6",
    buttonText: "Learn more",
    isNewTab: false,
    buttonVariant: "primary",
    // url: `/resources/webinars/${attributes?.slug}`,
    buttonCustomStyle: "padding: 14px 20px;",
    headlineHighlightedVariant: "xsmall",
  });
});

jobs.forEach((job: containerType) => {
  jobsContainer.value.wrapLinks.push({
    headlineHighlighted: job.attributes?.headlineGroup?.title,
    subTitle: job.attributes?.headlineGroup?.subtitle,
    subTitleClassName:
      "text-black-200 text-bodyXLarge text-white font-medium uppercase font-mierb leading-6",
    buttonText: "Learn more",
    isNewTab: false,
    buttonVariant: "primary",
    url: `/careers/${job.attributes.slug}`,
    buttonCustomStyle: "padding: 14px 20px;",
    headlineHighlightedVariant: "xsmall",
  });
});

events.data.forEach((event: containerType) => {
  const [year, month, day] = event?.attributes?.sections[0].date
    .split("T")[0]
    .split("-");
  const currentDate = new Date();
  const eventDate = new Date(event?.attributes?.sections[0].date);
  if (eventDate > currentDate) {
    eventsContainer.value.wrapLinks.push({
      headlineHighlighted: event.attributes.title,
      subTitle: `${day}.${month}.${year} - ${event.attributes.sections[0]?.location}`,
      subTitleClassName:
        "text-black-200 text-bodyXLarge text-white font-medium uppercase font-mierb leading-6",
      buttonText: "Learn more",
      isNewTab: false,
      buttonVariant: "primary",
      url: `/events/${event.attributes.slug}/`,
      buttonCustomStyle: "padding: 14px 20px;",
      headlineHighlightedVariant: "xsmall",
    });
  }
});
</script>
<style lang="css">
.ticker .py-14 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>
